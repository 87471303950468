<template>
  <v-card color="blue-grey lighten-5" class="rounded-lg overflow-hidden">
    <v-card-text class="px-0 pb-0">
      <v-row style="border-radius: 0">
        <v-col cols="5" class="pr-0">
            <v-col cols="12" class="py-6 px-12 fill-height d-flex flex-column" style="justify-content: center" >
              <v-toolbar-title class="d-flex flex-column title pl-0">
                <img v-if="!_.isNull(item._imagePath)" :src="item._imagePath" max-height="50%">
                <v-icon v-else size="125" color="teal">mdi-image-filter-center-focus-weak</v-icon>
              </v-toolbar-title>
              <v-toolbar-title class="d-flex flex-column title pl-0" style="margin-top: auto">
                {{ item.value }}
              </v-toolbar-title>
                <p class="">{{item.description}}</p>
            </v-col>
        </v-col>
        <v-col cols="7" class="grey lighten-3 elevation-4 py-8 px-8">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-icon @click="onCancel">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col class="mb-4">
              <div class="button text-uppercase pb-4">
                {{ $vuetify.lang.t('$vuetify.sites.catalogue.one.ui.families.name') }}
              <v-tooltip left max-width="20vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="pl-3 pr-3 text-right float-right" color="red darken-4" v-bind="attrs"
                          v-on="on">mdi-account-question-outline
                  </v-icon>
                </template>
                <span> {{ $vuetify.lang.t('$vuetify.sites.catalogue.one.ui.families.info') }}</span>
              </v-tooltip>
              </div>
              <v-divider></v-divider>
              <div class="address-container pt-4">
                <div class="address-item d-flex" v-if="!_.isEmpty(item._families)">
                  <v-chip v-for="family in item._families"
                          :key="family.id"
                          class="ma-2"
                  >
                    {{ family.value }}
                  </v-chip>
                   </div>
                <div v-else>
                  {{ $vuetify.lang.t('$vuetify.sites.catalogue.one.ui.families.none') }}
                </div>
              </div>
            </v-col>
            <v-col class="mb-4">
              <div class="button text-uppercase pb-4">
                {{ $vuetify.lang.t('$vuetify.sites.catalogue.one.ui.produits.name') }}
              </div>
              <v-divider></v-divider>
              <div class="address-container pt-4">
                <div class="address-item d-flex" v-if="(item._totalProducts !== 0)">
{{item._totalProducts }}  {{ $vuetify.lang.t('$vuetify.sites.catalogue.one.ui.produits.total') }}
                </div>
                <div v-else>
                  {{ $vuetify.lang.t('$vuetify.sites.catalogue.one.ui.produits.none') }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    }
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>
